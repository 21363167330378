import React from "react"
import Layout from "../components/Layout"
const About = () => {
  return (
    <Layout>
      <section className="newsletter-page">
        <div style={{ padding: "8rem" }}>
          <h4>TERMS & CONDITIONS</h4>
          <div>
            TheDigitalSpecs.com is owned and operated by ECRONSOFT APP PVT LTD.
            The website may contain commercials, sponsored gratified, paid
            insertions, affiliate links or different forms of monetization.
            TheDigitalSpecs.com abides by word of mouth marketing standards. We
            trust in morality of connection, estimation and uniqueness. The
            recompense received may affect the advertising content, topics or
            posts made in this blog. That content, advertising or post will be
            clearly identified as paid or sponsored provided. We have unique
            relationships with many manufacturers and dealers who provide us
            with product information starting from before an official phone,
            camera and other products announcement through to its launch. Where
            as possible, we verify this given information during our evaluation
            process. Additionally, we love smartphone, laptop, camera etc, so we
            are always checking and double checking our data, often in
            combination with user feedback from you, our readers. If a
            particular specification is vital to you, we always recommend you to
            double checking with the product seller and the best way to start is
            by visiting their website directly. Even given our enormous efforts
            we cannot guarantee that the information on our Product
            Specifications page is hundred percent correct. The Digital Specs
            will not responsible for any warranty of products, you will check
            with corresponding manufacturer or provider and their terms and
            conditions. If you think that any information for the product is
            erroneous or absent, please contact us here. The Digital Specs is
            never directly compensated to provide opinion on products, services,
            websites and various other topics. The views and thoughts expressed
            on this website are purely those of the other partner who are
            closely associated us. As we are not selling or involving directly
            any product from; therefore, any product right, statistic, quote or
            other representation about a product or service should be verified
            with the manufacturer or provider. The Digital Specs is not
            responsible for any warranties, or assurances as to the accuracy,
            currency or fullness of the content contain on this website or any
            sites linked to or from this site to other site.
          </div>
          <div
            style={{
              fontSize: "1.25rem",
              marginTop: "2rem",
              marginBottom: "2rem",
            }}
          >
            Thanks, TheDigitalSpecs Team V. Tony, Writer Editor | M. Shantu,
            Reviewer
          </div>

          <form
            className="contact-form"
            name="contact"
            method="post"
            netlify-honeypot="bot-field"
            data-netlify="true"
            action="/success"
          >
            <input type="hidden" name="bot-field" />
            <input type="hidden" name="form-name" value="contact" />

            <input
              type="text"
              name="name"
              placeholder="Your name"
              className="form-control "
            />
            <input
              type="email"
              name="email"
              className="form-control"
              placeholder="Your email"
            />
            <button type="submit" className="btn form-control submit-btn">
              subscribe
            </button>
          </form>
        </div>
      </section>
    </Layout>
  )
}

export default About
